import React, { useState, useEffect } from 'react';

import { AiFillDiff, AiFillFlag, AiFillPlusCircle } from 'react-icons/ai';
import TagsService from '../../../Services/TagService';
import SchemeService from '../../../Services/SchemeService';
import TagUpdateOptionals from './TagUpdateOptionals';

import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import EntryDialog from '../../../Components/BasicComponents/EntryDialog';
import BusinessService from '../../../Services/BusinessService';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';

type Props = {
  loadTagDetails: (tagId: string) => void,
  tagId: string,
  isCreateTag: boolean,
  isLive: boolean,
  useEncode: boolean,
  setOpenFlow: (toggle: boolean) => void,
  setActivationCode?: (code: string) => void,
};

function TagUpdate(props: Props) {
  const {
    loadTagDetails,
    isCreateTag,
    isLive,
    tagId,
    useEncode,
    setOpenFlow,
    setActivationCode,
  } = props;

  const [openEncodeDialog, setOpenEncodeDialog] = useState<boolean>(false);
  const [encodeNumber, setEncodeNumber] = useState<string>('');
  const [encodeUsed, setEncodeUsed] = useState<boolean>(false);
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const [openRewardDialog, setOpenRewardDialog] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openLocationDialog, setOpenLocationDialog] = useState<boolean>(false);
  const [openSchemeDialog, setOpenSchemeDialog] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [newLocationId, setNewLocationId] = useState<string>('');
  const [newSchemeId, setNewSchemeId] = useState<string>('');
  const [newTagId, setNewTagId] = useState<string>('');
  const [schemeError, setSchemeError] = useState<boolean>(false);
  const [selectOptions, setSelectOptions] = useState<string[]>([]);
  const [tagError, setTagError] = useState<boolean>(false);
  const [openOptional, setOpenOptional] = useState<boolean>(false);
  const [letter, setLetter] = useState<string>('');
  const [genCode, setGenCode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPOS, setIsPOS] = useState<boolean>(false);

  useEffect(() => {
    if (isCreateTag) {
      if (useEncode) setOpenEncodeDialog(true);
      else setOpenCreateDialog(true);
    } else {
      setOpenSchemeDialog(true);
    }
  }, [isCreateTag]);

  const CheckTagExists = async (tag?: string) => {
    setIsLoading(true);
    try {
      if (tag) await TagsService.getTag(tag, true);
      else await TagsService.getTag(newTagId, true);
      setEncodeUsed(true);
      setTagError(true);
    } catch (error:any) {
      setOpenCreateDialog(false);
      setOpenEncodeDialog(false);
      setOpenSchemeDialog(true);
    }
    setIsLoading(false);
  };

  const checkEncodedNumber = async () => {
    setEncodeUsed(false);
    setIsLoading(true);
    try {
      const preencode = await TagsService.getTagIdByPreEncode(encodeNumber);
      await setNewTagId(preencode.tagId);
      CheckTagExists(preencode.tagId);
    } catch (error: any) {
      setTagError(true);
    }
    setIsLoading(false);
  };

  const ValidateScheme = async () => {
    setIsLoading(true);
    const partnerId = newSchemeId.split('_')[0];
    try {
      const foundScheme:any = await SchemeService.getSpecificScheme(partnerId, newSchemeId);
      const businessData:any = await BusinessService.getBusiness(partnerId);
      setIsPOS(businessData?.isPosBusiness);
      const filtered = foundScheme.locations.filter((location: string) => location.includes('-'));
      const optionArray: string[] = [];
      for (let i = 0; i < filtered.length; i += 1) {
        let option = filtered[i];
        const location = businessData.Locations[filtered[i]];
        if (location !== undefined) {
          option += ` : ${location['Short Address']}`;
        }
        optionArray.push(option);
      }
      setSelectOptions(optionArray);
      setOpenSchemeDialog(false);
      setOpenLocationDialog(true);
    } catch (error:any) {
      setSchemeError(true);
    }
    setIsLoading(false);
  };

  const handleLocationConfirm = () => {
    setOpenLocationDialog(false);
    if (isCreateTag) {
      setOpenOptional(true);
    } else {
      setOpenConfirmDialog(true);
    }
  };

  const HandleCancel = () => {
    if (openEncodeDialog) setOpenEncodeDialog(false);
    if (openCreateDialog) setOpenCreateDialog(false);
    if (openSchemeDialog) setOpenSchemeDialog(false);
    if (openLocationDialog) setOpenLocationDialog(false);
    if (openConfirmDialog) setOpenConfirmDialog(false);
    if (openRewardDialog) setOpenRewardDialog(false);
    if (openOptional) setOpenOptional(false);
    if (isLoading) setIsLoading(false);
    setEncodeNumber('');
    setNewTagId('');
    setNewLocationId('');
    setNewSchemeId('');
    setOpenFlow(false);
  };

  const HandleErrorClose = () => {
    setErrorMessage('');
    setOpenError(false);
  };

  const HandleSaveChanges = async () => {
    setIsLoading(true);
    const locationId = newLocationId.split(' ')[0];
    await TagsService.updateTag(
      tagId,
      newSchemeId,
      locationId,
      isPOS ? 'POS' : 'regular',
      isPOS ? 'points' : '',
      !isLive,
    )
      .then(() => {
        loadTagDetails(tagId);
      })
      .then(() => {
        setNewLocationId('');
        setNewSchemeId('');
        setOpenConfirmDialog(false);
        setOpenFlow(false);
      });
    setIsLoading(false);
  };

  const HandleCreateTag = async () => {
    setIsLoading(true);
    const locationId = newLocationId.split(' ')[0];
    try {
      await TagsService.createTag(
        newTagId,
        newSchemeId,
        locationId,
        isPOS ? 'POS' : 'regular',
        isPOS ? 'points' : '',
        genCode,
        letter,
      )
        .then((tagData: any) => {
          if (setActivationCode) setActivationCode(tagData.code);
          loadTagDetails(newTagId);
        })
        .then(() => {
          setNewLocationId('');
          setNewSchemeId('');
          setNewTagId('');
          setOpenConfirmDialog(false);
          setOpenFlow(false);
        });
    } catch (e: any) {
      setOpenError(true);
      setErrorMessage(e.message);
    }
    setIsLoading(false);
  };

  return (
    <>
      <EntryDialog
        dialogTitle="Pre-Encoded Number"
        dialogText="Please enter the Pre-Encoded Number for this Tag"
        error={tagError}
        errorMessage={encodeUsed
          ? 'Tag for this Pre-Encoded Number is already used !'
          : 'This Pre-Encoded Number doesn\'t exist !'}
        handleCancel={HandleCancel}
        handleConfirm={checkEncodedNumber}
        open={openEncodeDialog}
        setError={setTagError}
        setValue={setEncodeNumber}
        value={encodeNumber}
        valueIcon={<AiFillPlusCircle />}
        loading={isLoading}
      />

      <EntryDialog
        dialogTitle="Tag ID"
        dialogText="Please enter the ID for this Tag. If the tagId does not start with 04
          then its most likely its pre-encode number and can't be created using this method."
        error={tagError}
        errorMessage="This Tag ID already exists !"
        handleCancel={HandleCancel}
        handleConfirm={() => CheckTagExists(newTagId)}
        open={openCreateDialog}
        setError={setTagError}
        setValue={setNewTagId}
        value={newTagId}
        valueIcon={<AiFillPlusCircle />}
        disabled={newTagId === '' || newTagId?.length < 12 || !newTagId.startsWith('04')}
        loading={isLoading}
      />

      <EntryDialog
        dialogTitle="Scheme ID"
        dialogText="Please enter a new Scheme ID for this tag"
        error={schemeError}
        errorMessage="This Scheme ID does not exist"
        handleCancel={HandleCancel}
        handleConfirm={ValidateScheme}
        open={openSchemeDialog}
        setError={setSchemeError}
        setValue={setNewSchemeId}
        value={newSchemeId}
        valueIcon={<AiFillDiff />}
        loading={isLoading}
      />

      <EntryDialog
        dialogTitle="Location ID"
        dialogText="Please select a new Location ID for this tag"
        handleCancel={HandleCancel}
        handleConfirm={handleLocationConfirm}
        open={openLocationDialog}
        setValue={setNewLocationId}
        selectOptions={selectOptions}
        value={newLocationId}
        valueIcon={<AiFillFlag />}
        loading={isLoading}
      />

      <TagUpdateOptionals
        handleCancel={HandleCancel}
        handleConfirm={() => {
          setOpenOptional(false);
          setOpenConfirmDialog(true);
        }}
        open={openOptional}
        setLetter={setLetter}
        letter={letter}
        setGenCode={setGenCode}
        genCode={genCode}
        loading={isLoading}
      />

      {openError && (
        <ErrorDialog dialogTitle="Error" dialogText={errorMessage} open={openError} handleClose={HandleErrorClose} />
      )}

      {isCreateTag ? (
        <ConfirmDialog
          dialogTitle="CREATE TAG"
          dialogText={`Are you sure you want to create this tag?\n
            ${encodeNumber ? `Encoded Number : ${encodeNumber}` : ''}
            Tag ID : ${newTagId}
            Tag Type : ${isPOS ? 'POS' : 'regular'}
            Tag Reward : ${isPOS ? 'POS' : 'stamps'}
            Scheme ID : ${newSchemeId}
            Location ID : ${newLocationId}
            Tag Letter : ${letter || 'A'}
            Generate Activation Code : ${genCode ? 'Yes' : 'No'}`}
          open={openConfirmDialog}
          handleCancel={HandleCancel}
          handleConfirm={HandleCreateTag}
          isLoading={isLoading}
        />
      ) : (
        <ConfirmDialog
          dialogTitle="SAVE CHANGES"
          dialogText={`Are you sure you want to save these changes?\n
            Tag Type : ${isPOS ? 'POS' : 'regular'}
            Tag Reward : ${isPOS ? 'POS' : 'stamps'}
            Scheme ID : ${newSchemeId}
            Location ID : ${newLocationId}`}
          open={openConfirmDialog}
          handleCancel={HandleCancel}
          handleConfirm={HandleSaveChanges}
          isLoading={isLoading}
        />
      )}
    </>
  );
}

export default TagUpdate;
